import React, { Component } from "react";

import styled from 'styled-components';

import { Carousel, ICarouselImage } from "../../shared/carousel";
import { PhotoEssayHeader } from "../../shared/article";
import { Divider, Highlight  } from "../../shared/styling";
import { ArticlePhotoFlexible } from "../../shared/article";
import { Map } from "../../shared/map";


const Article = styled.div`
  width: 100%;
  margin-top: 30px;
`

const ArticleHeader = styled.div`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;
  font-family: "Adelle Sans Bold";
`

const ArticleDate = styled.div`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  color: #666666;
  font-size: 0.8rem;
`

const ArticleP = styled.p`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  line-height: 1.7rem;
`

const carouselImages: Array<ICarouselImage> = [
  	{
      url: "http://cdn.cnn.com/cnnnext/dam/assets/180712160742-silt-island-on-kati-thanda-lake-eyre-with-wrightsair-2-1.jpg",
      caption: "1. A sample caption."
    },
  	{
      url: "https://static01.nyt.com/images/2021/06/18/world/00hk-bookstores1/00hk-bookstores1-superJumbo.jpg?quality=90",
      caption: "2. Caption 2"
    },
  	{
      url: "https://static01.nyt.com/images/2021/07/06/science/06JUNO-02/06JUNO-02-superJumbo.jpg?quality=90",
      caption: "3. Sed elit erat, suscipit mollis nisi et, facilisis aliquet purus. Ut pellentesque enim nec convallis consectetur. Suspendisse varius ex et aliquet suscipit. Nulla vulputate interdum ante, fringilla mattis dolor pulvinar sit amet. Duis dignissim lacus vitae lacinia tincidunt. Fusce interdum pellentesque odio, vel tristique lectus."
    },
  	{
      url: "https://www.icegif.com/wp-content/uploads/snow-icegif-29.gif",
      caption: "4. It's Christmas"
    },
]

export const Sample = () => {
  return (
    <>
      <PhotoEssayHeader
        imageSource="https://static01.nyt.com/images/2021/07/06/science/06JUNO-02/06JUNO-02-superJumbo.jpg?quality=90"
        title="A Sample Article"
        description="With a sample article description."
        caption="This is a sample caption for a sample article's photo."
        captionSource="NASA/JPL"
      />
      <Divider />
      <Article>
        <ArticleHeader>Title Sample</ArticleHeader>
        <ArticleDate>January 3rd, 2023</ArticleDate>
        <ArticleP>
          <Highlight color="yellow">Lorem</Highlight> ipsum dolor sit amet, consectetur adipiscing elit. Etiam tempor sit amet elit eu fermentum. Maecenas volutpat venenatis purus, eget pretium nunc euismod ut. Maecenas a libero molestie, mattis dui.
        </ArticleP>
        <ArticleP>
          Sed elit erat, suscipit mollis nisi et, facilisis aliquet purus. Ut pellentesque enim nec convallis consectetur. Suspendisse varius ex et aliquet suscipit. Nulla vulputate interdum ante, fringilla mattis dolor pulvinar sit amet. Duis dignissim lacus vitae lacinia tincidunt. Fusce interdum pellentesque odio, vel tristique lectus.
        </ArticleP>
        <ArticleP>
          Fusce eu ipsum eleifend, imperdiet lectus a, vulputate libero.
        </ArticleP>
        <ArticlePhotoFlexible
          src="https://static01.nyt.com/images/2021/06/18/world/00hk-bookstores1/00hk-bookstores1-superJumbo.jpg?quality=90"
          caption="A sample caption."
        />
        <ArticleP>
          Praesent augue felis, dictum non dolor in, tempor commodo erat. Duis dignissim egestas erat, non congue risus mattis eget. Mauris porttitor dictum metus, nec lacinia nulla lobortis ac. Quisque in augue massa. Integer volutpat consequat tortor. Duis tincidunt, elit eu mattis mollis, dui erat euismod nisi, ac convallis enim nunc vitae nibh. Vivamus finibus nibh nulla, ut venenatis tellus varius vitae. Fusce ultricies justo maximus purus iaculis, et eleifend mi bibendum.
        </ArticleP>
        <ArticleP>
          Maecenas in venenatis dui. Curabitur vulputate bibendum nisl non vulputate. Duis viverra dignissim erat, vel ornare diam porta vitae. Aenean fermentum, diam quis dignissim dictum, justo lorem malesuada ipsum, in pulvinar tortor dui id sem.
        </ArticleP>
        <ArticleP>
          Donec faucibus arcu eu odio hendrerit cursus. Sed pellentesque scelerisque porttitor. Morbi luctus nunc nec ipsum mollis, eu iaculis velit condimentum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        </ArticleP>
        <Carousel images={carouselImages} />
        <Divider />
        <ArticleP>
          Maecenas in venenatis dui. Curabitur vulputate bibendum nisl non vulputate. Duis viverra dignissim erat, vel ornare diam porta vitae. Aenean fermentum, diam quis dignissim dictum, justo lorem malesuada ipsum, in pulvinar tortor dui id sem.
        </ArticleP>
        <ArticleP>
          Donec faucibus arcu eu odio hendrerit cursus. Sed pellentesque scelerisque porttitor. Morbi luctus nunc nec ipsum mollis, eu iaculis velit condimentum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        </ArticleP>
        <ArticlePhotoFlexible
          src="http://cdn.cnn.com/cnnnext/dam/assets/180712160742-silt-island-on-kati-thanda-lake-eyre-with-wrightsair-2-1.jpg"
          caption="Another sample caption."
        />
        <ArticleP>
          Nulla elementum feugiat purus, vitae congue turpis tempus a. Quisque quis malesuada tellus. Vivamus dignissim in ex eget lacinia. Suspendisse sollicitudin arcu vel libero feugiat elementum. In egestas, erat at feugiat hendrerit, ex erat hendrerit tellus, euismod ornare tortor ipsum ac nisi.
        </ArticleP>
        <Map location="Second+Home+Hostel" mapCaption="Second Home Hostel -- The hostel I stayed in during my two days in Istanbul"/>
        <ArticleP>
          Nulla elementum feugiat purus, vitae congue turpis tempus a. Quisque quis malesuada tellus. Vivamus dignissim in ex eget lacinia. Suspendisse sollicitudin arcu vel libero feugiat elementum. In egestas, erat at feugiat hendrerit, ex erat hendrerit tellus, euismod ornare tortor ipsum ac nisi.
        </ArticleP>
      </Article>
    </>
  )
}
