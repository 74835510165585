import React, { Component } from "react";

import { EssayContainer, EssayHeader, EssayP } from "../../shared/essay";


export const Basic = () => {
	return (
		<>
			<EssayContainer>
				<EssayHeader>Hello, World</EssayHeader>
				<EssayP>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum elementum leo a justo porttitor, vitae lacinia tellus pulvinar. Nunc dolor tellus, mattis at iaculis vitae, malesuada vitae nibh.
				</EssayP>
				<EssayP>
					Vivamus maximus ligula eros, eu accumsan libero rhoncus in. Sed at vulputate nibh, non vulputate nisi. Donec finibus urna nulla, in luctus arcu tincidunt quis. Etiam et porta eros, et accumsan nisl. Vestibulum interdum, justo et lacinia venenatis, massa mauris ultricies enim, a blandit erat tellus in lacus.
				</EssayP>
				<EssayP>
					Donec mauris est, iaculis vel tortor placerat, semper pharetra dolor. Morbi in interdum elit.
				</EssayP>
				<EssayP>
					Praesent bibendum, enim nec vulputate scelerisque, risus odio viverra urna, nec molestie dui felis id arcu. Aenean varius ipsum sem, vel dignissim justo consectetur eget. Sed id porttitor urna, sed malesuada nunc. Aliquam fringilla, nibh sit amet sagittis tincidunt, neque diam bibendum augue, at egestas nunc nisl eu ipsum.
				</EssayP>
				<EssayP>Andrew Tallos</EssayP>
				<EssayP>July 3rd, 2023</EssayP>
			</EssayContainer>
		</>
	)
}
