import React, { Component, SyntheticEvent } from "react";

import styled from 'styled-components';


export const Article = styled.div`
  width: 100%;
  margin-top: 30px;
`

export const ArticleTitle = styled.div`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;
  font-family: "Adelle Sans Bold";
  `

export const ArticleDate = styled.div`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  color: #666666;
  font-size: 0.8rem;
`

export const ArticleP = styled.p`
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  line-height: 1.7rem;
`

interface IArticleHeader {
	title: string;
	date: string;
}

export const ArticleHeader = ({ title, date }: IArticleHeader) => {
	return (
		<>
			<ArticleTitle>{title}</ArticleTitle>
			<ArticleDate>{date}</ArticleDate>
		</>
	)
}

const ArticlePhotoFlexibleWrapper = styled.figure`
  max-width: 100vw;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  &.full-screen {
    margin: 0;
    padding: 0;
    max-height: 100vh;
    justify-content: center;
    text-align: center;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    transition: all 0.3s ease-in-out;

    img {
      max-height: 85vh;
    }

    figcaption {
      max-height: 15vh;
    }
  }
`

const ArticlePhotoFlexibleImg = styled.img`
  position: relative;
  width: 80vw;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
`

const ArticlePhotoFlexibleCaption = styled.figcaption`
  width: 40vw;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  color: #666666;
`

const MinimiseIconButton = styled.div`
  position: absolute;
  z-index: 1000;
  top: 20;
  right: 25px;
  fill: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 0.2s ease-in;

  &:hover {
    border: solid 1px antiquewhite;
    border-radius: 50%;
    background: antiquewhite;
  }
`

const MinimiseIcon = styled.svg`
  width: 60px;
  height: 60px;
  fill: none;
`

export const ArticlePhotoFlexible: React.FC<{ src: string, caption: string }> = ({src, caption}) => {
  const focusedImageRef = React.useRef(null);
  const [showMinimiseButton, setShowMinimiseButton] = React.useState(false)
  
  const makeFullScreen = (element: React.MouseEvent): void => {
    // While we want the image to be clickable (as it has more precise boundaries than its wrapper), the
    // element actually being full screened should be the wrapper, as that gives us better / more controlled
    // spacing for our image and caption. Thus, we'll take the wrapper here and apply the styles to it.
    const imageWrapper = (element.target as HTMLElement).parentElement

    imageWrapper.classList.add("full-screen")
    focusedImageRef.current = imageWrapper;
    
    // This allows us to hide everything but our photo, and prevents scrolling while the photo is maximised.
    document.body.style.overflow = "hidden";

    // Summon a trusty button to minimise the photo.
    setShowMinimiseButton(true);
  }

  const minimiseFullScreen = (): void => {
    const imageElement: HTMLElement = focusedImageRef.current;

    imageElement.classList.remove("full-screen")

    document.body.style.removeProperty("overflow");

    setShowMinimiseButton(false);
  }

  return (
    <ArticlePhotoFlexibleWrapper ref={focusedImageRef}>
      <ArticlePhotoFlexibleImg src={src} onClick={makeFullScreen} />
      {showMinimiseButton && <MinimiseIconButton onClick={minimiseFullScreen}>
        <MinimiseIcon>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.9665 25.3668L38.5997 28H32V21.4003L34.6332 24.0335L38.6667 20L40 21.3333L35.9665 25.3668Z"
            fill="#000"
          /><path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0335 34.6332L21.4003 32H28V38.5997L25.3668 35.9665L21.3333 40L20 38.6667L24.0335 34.6332Z"
            fill="#000"
          ></path>
        </MinimiseIcon>
      </MinimiseIconButton>
      }
      <ArticlePhotoFlexibleCaption>{caption}</ArticlePhotoFlexibleCaption>
    </ArticlePhotoFlexibleWrapper>
  )
}

const PhotoHeaderContainerMaster = styled.div`
  width: 100vw;
`

const PhotoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const PhotoHeaderImage = styled.img`
  width: 50vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
}
`

const PhotoHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  background-color: #000000;
`

const PhotoHeaderTextMain = styled.div`
  color: #fff;
  font-size: 3.8rem;
  font-family: "Adelle Sans Bold";
  margin-bottom: 10px;
  text-align: center;
`

const PhotoHeaderTextDescription = styled.div`
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  font-family: "Adelle Sans Light";
  width: 80%;
`

const PhotoHeaderCaption = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 15px;
  text-align: left;
  color: #666666;
`

const PhotoHeaderCaptionImageSource = styled.span`
  color: #888;
  margin-left: 5px;
`


interface IPhotoEssay {
	imageSource: string;
	title: string;
	description: string;
	caption: string;
	captionSource: string;
}

export const PhotoEssayHeader = ({
	imageSource,
	title,
	description,
	caption,
	captionSource
}: IPhotoEssay) => {
	return (
		<>
			<PhotoHeaderContainerMaster>
				<PhotoHeaderContainer>
					<PhotoHeaderImage src={imageSource} />
					<PhotoHeaderTextContainer>
						<PhotoHeaderTextMain>{title}</PhotoHeaderTextMain>
						<PhotoHeaderTextDescription>
							{description}
						</PhotoHeaderTextDescription>
					</PhotoHeaderTextContainer>
				</PhotoHeaderContainer>
				<PhotoHeaderCaption>
					{caption}
					<PhotoHeaderCaptionImageSource>{captionSource}</PhotoHeaderCaptionImageSource>
				</PhotoHeaderCaption>
			</PhotoHeaderContainerMaster>
		</>
	)
}
