import React, { Dispatch, useState } from "react";
import styled from "styled-components";


const CarouselWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: auto;
	height: auto;
	max-height: 100vh;
	text-align: center;
	vertical-align: baseline;
`

const CarouselImages = styled.ol`
	display: flex;
	margin: 0;
	padding: 0;
	height: 85vh;
	text-align: center;
	overflow-x: scroll;
	overflow-y: hidden;
	align-items: center;

	li {
		margin: 0;
		padding: 0;
		max-height: 100%;
		list-style: none
		flex: 0 0 100%;
	}
`

export interface ICarouselImage {
	url: string;
	caption: string;
}

const CarouselImageWrapper = styled.figure`
	margin: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const CarouselImg = styled.img`
	object-fit: contain;
	width: 60vw;
	height: 90%;
`

const CarouselImgCaption = styled.figcaption`
	height: 10%;
	max-width: 60vw;
	overflow-y: scroll;
	display: flex;
	align-items: center;
  font-family: "Adelle Sans Light";
  color: #666666;
`

const CarouselImage: React.FC<{ image: ICarouselImage }> = ({image}) => {
	return (
		<CarouselImageWrapper>
			<CarouselImg src={image.url} />
			<CarouselImgCaption>{image.caption}</CarouselImgCaption>
		</CarouselImageWrapper>
	)
}

const CarouselControlWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3vh;
	gap: 0.8em;
`

const CarouselControlButtonWrapper = styled.div`
	display: flex;
	gap: 0.6em;
`

const CarouselControlButtonStyle = styled.button`
	width: 26px;
	height: 26px;
	border: 1px solid hsl(0deg 0% 87%);
	border-radius: 50%;
	cursor: pointer;
`

enum ArrowDirection { Left = 1, Right = -1 }
const CarouselControlButton: React.FC<{ action: Function, arrowDirection: ArrowDirection}> = ({action, arrowDirection}) => {
	return (
		<>
			<CarouselControlButtonStyle onClick={() => action() }>
				<svg width={10} height={12} viewBox="0 0 10 12" transform={`scale(${arrowDirection})`}>
					<path d="M6.60156 11.2002L1.40156 6.0002L6.60156 0.800197>"></path>
				</svg>
			</CarouselControlButtonStyle>
		</>
	);
}

const CarouselControlIndicatorWrapper = styled.div`
	display: flex;
`

const CarouselControlIndicator = styled.li`
	direction: rtl
`


interface CarouselControls {
  currentIndex: number
  wrapperIndex: number
  updateDisplayIndex: Function
}

const CarouselControls = ({currentIndex, wrapperIndex, updateDisplayIndex}: CarouselControls) => {
	const calculateNextIndex = (proposal: number, wrapper: number) => {
		const inBounds: boolean = (proposal >= 0) && (proposal <= wrapper)
		if (inBounds) {
			return proposal;
		}
		
		return proposal < 0 ? wrapper : 0;
	}

	const carouselControlIndicators = () => {
		const indicators: Array<JSX.Element> = [];
		for (let i = 0; i <= wrapperIndex; i++) { 
			let indicator: JSX.Element = <CarouselControlIndicator style={{ color: i === currentIndex ? "black" : "hsl(0deg 0% 87%)"}} />
			indicators.push(indicator);
		}

		return indicators;
	}
	
	return (
		<>
			<CarouselControlWrapper>
				<CarouselControlIndicatorWrapper>
					{carouselControlIndicators()}
				</CarouselControlIndicatorWrapper>
				<p>|</p>
				<CarouselControlButtonWrapper>
					<CarouselControlButton
						arrowDirection={ArrowDirection.Left}
						action={() => {
							updateDisplayIndex(calculateNextIndex(currentIndex - 1, wrapperIndex))
						}}
					/>
					<CarouselControlButton
						arrowDirection={ArrowDirection.Right}
						action={() => {
							updateDisplayIndex(calculateNextIndex(currentIndex + 1, wrapperIndex))
						}}
					/>
				</CarouselControlButtonWrapper>
			</CarouselControlWrapper>
		</>
	)
}

export const Carousel = ({images}: { images: Array<ICarouselImage> }) => {
	const [displayIndex, setDisplayIndex] = useState<number>(0);
	const wrapperIndex: number = images.length - 1;

	// Only render the carousel image that is currently selected.
	const displayImages = images.map((image, imageIndex) => {
		return (
			<li style={{display: imageIndex === displayIndex ? "inline" : "none"}}>
				<CarouselImage key={imageIndex} image={image} />
			</li>
		)
	});
	
	return (
		<>
			<CarouselWrapper>
				<CarouselImages>
					{displayImages}
				</CarouselImages>
				<CarouselControls currentIndex={displayIndex} wrapperIndex={wrapperIndex} updateDisplayIndex={setDisplayIndex} />
			</CarouselWrapper>
		</>
	)
}