import styled from 'styled-components';


export const Highlight = styled.span`
	background-color: ${props => props.color};
`

export const Divider = styled.div`
  border-bottom: 1px solid #ebebeb;
  width: 40vw;
  margin: 25px auto;
`