import React, { Component } from "react";

import styled from 'styled-components';


export const EssayContainer = styled.div`
	margin-top: 15px;
	margin-left: 10px;
	padding-top: 2px;
	padding-left: 10px;
	background-color: #FFF5EE;
`

export const EssayHeader = styled.h2`
	font-size: 1.5em;
	font-family: "Adelle Sans Light"
	margin-top: 10px;
	margin-bottom: 20px;
`

export const EssayP = styled.p`
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
`