import React, { Component } from "react";
import styled from 'styled-components';

export interface Article {
  title: string;
  date: string;
}

const ArticlePreviewRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const ArticlePreviewRowTitle = styled.div`
  color: blue;
  text-decoration: underline;

  a {
    font-size: 1.2rem;
    color: #111111;
    text-decoration: none;
  }
`

const ArticlePreviewRowDate = styled.div`
  margin-top: 2px;
  color: #828282;
  font-size: 0.7em;
`


export const ArticlePreview: React.FC<{ article: Article}> = ({article}) => {
  return (
    <ArticlePreviewRow>
      <ArticlePreviewRowTitle>
        <a href="/">{article.title}</a>
      </ArticlePreviewRowTitle>
      <ArticlePreviewRowDate>{article.date}</ArticlePreviewRowDate>
    </ArticlePreviewRow>
  );
};
