import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { NavigationBar } from "./components/navigation-bar/navigation-bar";
import Home from "./components/home/home";
import { About } from "./components/about/about";
import { ArticleFeed } from "./components/article-feed/article-feed";


class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <NavigationBar></NavigationBar>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/articles" component={ArticleFeed} />
            <Route path="/about" component={About} />
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
