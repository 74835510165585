import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


const NavigationBarMainRow = styled.div`
  position: sticky;
  z-index: 42;
  top: 0;
  display: flex;
  height: 60px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.8px #0100f4;
  background-color: #f9f9f9;
`

const NavigationBarOption = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;

  a {
    font-family: "Adelle Sans Bold";
    font-size: 1.35rem;
    color: #0100f4;
    text-decoration: none;
  }
`

const NavigationBarOptionDividerStyle = styled.span`
  font-family: "Adelle Sans Light";
  color: #0100f4;
`

const NavigationBarOptionDivider = () => {
  return (
    <NavigationBarOptionDividerStyle>|</NavigationBarOptionDividerStyle>
  );
}


export const NavigationBar = () => {
  return (
    <NavigationBarMainRow>
      <NavigationBarOption><Link to="/">Home</Link></NavigationBarOption>
      <NavigationBarOptionDivider/>
      <NavigationBarOption><Link to="/articles">Articles</Link></NavigationBarOption>
      <NavigationBarOption><Link to="/about">About</Link></NavigationBarOption>
    </NavigationBarMainRow>
  );
};
