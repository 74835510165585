import React from 'react';
import styled from 'styled-components';

const RandomArtwork = styled.div`
  text-align: center;

  img {
    width: 60vw;
    margin: 35px auto;
  }
`

const Home = () => {
  return (
    <RandomArtwork>
      <img src="https://images.squarespace-cdn.com/content/v1/5a5394dc2278e7ad14ff4832/1529481334515-LMXE26DY5K2INTQ4J5F6/ke17ZwdGBToddI8pDm48kGDpvalPb1SqHoCn1hwN0Y57gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmQPoRzxSr1hzN-vPBHt7YyLLXgctAyUJRqJUUGWVDK_ZzIgvsybGcZEPqUYiXY8im/escher.jpg?format=2500w" />
    </RandomArtwork>
  );
};

export default Home;
