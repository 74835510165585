import React from "react";

import styled from 'styled-components';


const MapWrapper = styled.figure`
  padding-top: 10px;
  padding-bottom: 10px;
`

const MapIframe = styled.iframe`
	width: 60vw;
	height: 80vh;
	border: 0;
	display: block;
	margin: 0 auto;
`

const MapCaption = styled.figcaption`
  width: 40vw;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  color: #666666;
`

const GMAPS_API = process.env.GMAPS_API


export const Map: React.FC<{location: string, mapCaption: string}> = ({location, mapCaption}) => {
	return (
		<MapWrapper>
			<MapIframe
				src={`https://www.google.com/maps/embed/v1/place?key=${GMAPS_API}&q=${location}`}
				loading="lazy"
				allowFullScreen={true}
				referrerPolicy="no-referrer-when-downgrade"
			>
			</MapIframe>
			<MapCaption>{mapCaption}</MapCaption>
		</MapWrapper>
	)
}