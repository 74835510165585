import React, { Component } from "react";
import styled from 'styled-components';
import { useRouteMatch,  Link, Switch, Route } from "react-router-dom";

import { Article, ArticlePreview } from "../article-feed/article-preview";
import { About } from "../about/about";
import { Sample } from "../article-feed/articles/sample"
import { Basic } from "../article-feed/articles/basic"
import { SectionHeader } from "../shared/header"


interface ArticleMapping {
  url: string;
  article: Article;
  article_component: React.FC;
}

const ArticleFeedList = styled(SectionHeader)`
  height: 90vh;
 `

export const ArticleFeed = () => {
  let match = useRouteMatch();

  const articles: ArticleMapping[] = [
    {
      url: "hello-world",
      article: {title: "Hello, world!", date: "2019-01-01"},
      article_component: About,
    },
    {
      url: "auto-deploy-update",
      article: { title: "Auto-deploys are running nicely", date: "2021-02-18" },
      article_component: About,
    },
    {
      url: "sample",
      article: { title: "Sample", date: "2021-06-17" },
      article_component: Sample,
    },
    {
      url: "basic",
      article: { title: "Basic", date: "2023-07-03" },
      article_component: Basic,
    },
  ]

  return (
    <Switch>
      <Route exact path={match.path}>
        <ArticleFeedList>
          {articles.map((article_mapping) => (
              <Link to={`${match.url}/${article_mapping.url}`}>
                <ArticlePreview article={article_mapping.article} />
              </Link>
          ))}
        </ArticleFeedList>
      </Route>

      {articles.map((article_mapping) => (
        <Route path={`${match.url}/${article_mapping.url}`}>
          <article_mapping.article_component />
        </Route>
      ))}
    </Switch>
  );
}
