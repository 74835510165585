import React, { Component } from "react";
import styled from 'styled-components';

import { SectionHeader } from "../shared/header"

const AboutContainer = styled(SectionHeader)`
  width: 90%;
  display: flex;
  flex-direction: column;
`

const AboutProfilePic = styled.div`
  margin: 0 auto;
`

const AboutProfile = styled.div`
  padding-bottom: 14px;
  font-size: 0.9em;
`

export const About = () => {
  return (
    <AboutContainer>
      <AboutProfilePic>
        <img src="https://avatars1.githubusercontent.com/u/10286033?s=460&v=4" />
      </AboutProfilePic>
      <AboutProfile>
      </AboutProfile>
    </AboutContainer>
  );
};
